/** @jsx jsx */
import { jsx, Flex, Styled } from 'theme-ui'
import { useState, useEffect, useRef, useCallback } from 'react'
import { useTransition, animated } from 'react-spring'

const AnimatedFlex = animated(Flex)

const ServiceTypesLabel = () => {
  const ref = useRef([])
  const [items, set] = useState([])

  const transitions = useTransition(items, null, {
    from: {
      opacity: 0,
      height: 0,
      innerHeight: 0,
      transform: 'perspective(600px) rotateX(0deg)',
      color: 'white',
    },
    enter: [{ opacity: 1, height: 20, innerHeight: 20 }, { transform: 'perspective(600px) rotateX(0deg)', color: 'white' }, { transform: 'perspective(600px) rotateX(0deg)' }],
    leave: [{ color: '#02BED6' }, { innerHeight: 0 }, { opacity: 0, height: 0 }],
    update: { color: 'white' },
  })

  const reset = useCallback(() => {
    ref.current.map(clearTimeout)
    ref.current = []
    set([])
    ref.current.push(setTimeout(() => set(['Our', 'Services']), 500))
    ref.current.push(setTimeout(() => set(['Services']), 3500))
    ref.current.push(setTimeout(() => set(['Our', 'Services']), 8000))
    ref.current.push(setTimeout(() => set(['Services']), 11500))
    ref.current.push(setTimeout(() => set(['Our', 'Services']), 16000))
    ref.current.push(setTimeout(() => set(['Services']), 19500))
    ref.current.push(setTimeout(() => set(['Our', 'Services']), 24000))
    // ref.current.push(setTimeout(() => set(['Services']), 27500))
  }, [])

  // useEffect(() => void reset(), [])
  useEffect(() => {
    reset()
    // eslint-disable-next-line
  }, [])

  return (
    <Flex
      onClick={reset}
      sx={{
        flexDirection: 'column',
        padding: ['0px 20px', '0px 40px', '0px 40px'],
      }}
    >
      {transitions.map(({ item, props: { innerHeight, ...rest }, key }) => (
        <AnimatedFlex
          sx={{
            overflow: 'hidden',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            textTransform: 'uppercase',
            willChange: 'transform, opacity, height',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            lineHeight: '1em',
            letterSpacing: '0.25em',
          }}
          key={key}
          style={rest}
          onClick={reset}
        >
          <animated.div
            style={{
              overflow: 'hidden',
              height: innerHeight,
            }}
            sx={{ pt: '1' }}
          >
            <Styled.h6 sx={{ mt: '0', mb: '1', px: '0' }}>{item}</Styled.h6>
          </animated.div>
        </AnimatedFlex>
      ))}
    </Flex>
  )
}

export default ServiceTypesLabel
