/** @jsx jsx */
import { jsx, Flex, Styled, useThemeUI } from 'theme-ui'
import { animated, useSpring, useChain, useTransition } from 'react-spring'
import { useToggle } from '../hooks'
import { Fragment, useRef } from 'react'
import ServiceData from '../data/landing/services'
import ServiceTypesLabel from './serviceTypesLabel'

const data = ServiceData

const AnimatedFlex = animated(Flex)

const ServiceTypes = () => {
  const { isToggled, toggle } = useToggle(false)
  const context = useThemeUI()
  const { theme } = context

  const themeBackgroundColor = theme.colors.services
  const borderColor = theme.colors.highlighted

  const serviceTitleRef = useRef()
  const serviceTitle = useSpring({
    ref: serviceTitleRef,
    opacity: isToggled ? 0 : 1,
    fontSize: isToggled ? '0.5rem' : '1em',
    transform: isToggled ? `translate3d(50, -50px, 1000)` : `translate3d(50, -50px, 1000)`,
  })

  const springRef = useRef()
  const { size, transform, ...rest } = useSpring({
    ref: springRef,
    from: {
      size: '40%',
      background: '#be4b52',
      border: `1px solid ${borderColor}`,
      borderRadius: '4px',
    },
    to: {
      size: isToggled ? '99%' : '40%',
      background: isToggled ? 'rgba(255,255,255,0)' : '#be4b52',
      border: isToggled ? '1px solid rgba(255,255,255,0)' : '1px solid #be4b52',
    },
  })

  const transitionRef = useRef()
  const transition = useTransition(isToggled ? data : [], (item) => item.name, {
    ref: transitionRef,
    trail: 400 / data.length,
    from: {
      opacity: 0,
      transform: 'scale(0)',
    },
    enter: {
      opacity: 1,
      transform: 'scale(1)',
    },
    leave: {
      opacity: 0,
      transform: 'scale(0)',
    },
  })

  useChain(isToggled ? [serviceTitleRef, springRef, transitionRef] : [transitionRef, springRef, serviceTitleRef], [0, 0.5, isToggled ? 1 : 3.3])

  return (
    <Fragment>
      <AnimatedFlex
        sx={{
          height: ['615px', '310px', '310px', '310px'],
          pt: '10px',
          pb: '5px',
          px: [0, 2, 4],
          justifyContent: 'center',
          alignItems: 'center',
          contain: 'content',
        }}
      >
        <animated.div
          style={serviceTitle}
          sx={{
            position: 'absolute',
            cursor: 'pointer',
          }}
          onClick={toggle}
        >
          <ServiceTypesLabel />
        </animated.div>

        <animated.button
          aria-label="Our services"
          style={{
            width: size,
            height: size,
            ...rest,
          }}
          sx={{
            variant: 'layout.customAccessibility',
          }}
          onClick={toggle}
        >
          <span
            sx={{
              cursor: 'pointer',
              display: 'grid',
              // gridTemplateColumns: ['99%', '49% 49%', '49% 49%'],
              gridTemplateColumns: ['repeat(1, minmax(100px, 1fr))', 'repeat(2, minmax(100px, 1fr))', 'repeat(2, minmax(100px, 1fr))'],
              gridGap: '3',
            }}
          >
            {transition.map(({ item, key, props }) => (
              <AnimatedFlex
                style={{ ...props, background: item.css }}
                key={key}
                // children={item.name}
                sx={{
                  minHeight: '60px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '4px',
                  my: 0,
                }}
              >
                <Styled.h6
                  sx={{
                    px: '32px',
                    // m: 'auto',
                    color: themeBackgroundColor,
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: '700',
                    letterSpacing: '1px',
                  }}
                >
                  {item.name}
                </Styled.h6>
              </AnimatedFlex>
            ))}
          </span>
        </animated.button>
      </AnimatedFlex>
    </Fragment>
  )
}

export default ServiceTypes
