/** @jsx jsx */
import { jsx, Box, Styled, useThemeUI, Flex } from 'theme-ui'
import { Fragment } from 'react'
import { useToggle } from '../hooks'
import { animated, useSpring } from 'react-spring'
import { ButtonAnimated } from './animation'
import { Waypoint } from 'react-waypoint'
import FeaturedProjects from './featuredProjects'

const AnimatedFlex = animated(Flex)
const AnimatedBox = animated(Box)
// const AnimatedThemeGrid = animated(Grid)

const WorkSection = ({ featuredProjects }) => {
  const { isToggled: active, toggle: setActive } = useToggle(false)

  const context = useThemeUI()
  const { theme } = context

  const headingFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 100 : 0,
  })
  const subtitleFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 250 : 0,
  })

  const gridFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 450 : 0,
  })
  const buttonFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 650,
  })

  return (
    <Fragment>
      <Box
        sx={{
          variant: 'layout.sectionContainer',
          bg: theme.colors.background,
          width: '100vw',
          position: 'relative',
          ml: '-50vw',
          left: '50%',
        }}
      >
        {/* <Container> */}
        <Waypoint
          bottomOffset="25%"
          onEnter={() => {
            if (!active) setActive(true)
          }}
        />
        <Flex sx={{ variant: 'layout.sectionHeroContainerDefault' }}>
          <AnimatedBox
            style={headingFade}
            sx={{
              variant: 'layout.headingContainer',
            }}
          >
            <Styled.h1 sx={{ variant: 'text.heading' }}>Featured work</Styled.h1>
          </AnimatedBox>
          <AnimatedBox
            style={subtitleFade}
            sx={{
              variant: 'layout.subHeadingContainer',
            }}
          >
            <Styled.h4 sx={{ variant: 'text.subheading' }}>What we do and how we do it.</Styled.h4>
          </AnimatedBox>
          {/* <AnimatedThemeGrid sx={{ my: [2, 3, 4], mx: [1, 5, 6] }} style={gridFade} gap={(1, 1, 1, 1)} columns={[`auto`, `auto`, `auto auto`]} variant="cards"> */}
          <AnimatedFlex style={gridFade} sx={{ mt: [2, 3, 4], justifyContent: 'center', flexWrap: 'wrap' }}>
            <FeaturedProjects featuredProjects={featuredProjects} />
          </AnimatedFlex>
          {/* </AnimatedThemeGrid> */}
        </Flex>
        {/* </Container> */}
        <AnimatedFlex style={buttonFade} sx={{ variant: 'layout.buttonContainerDefault' }}>
          <Box sx={{ m: 3 }}>
            <ButtonAnimated margin="0" variant="outline" scaleActive="1.1" label="See more work" linkTo="/work/" />
          </Box>
        </AnimatedFlex>
      </Box>
    </Fragment>
  )
}

export default WorkSection
