/** @jsx jsx */
import { jsx, Box, Styled, Flex, useThemeUI } from 'theme-ui'
import { Fragment } from 'react'
import { animated, useSpring } from 'react-spring'
import { ButtonAnimated } from './animation'
import Container from '../components/container'
import { ProductTypes } from './productTypes'
import Wave from './wave'
import { keyframes } from '@emotion/core'

const glitch1 = keyframes`
  0% {
    transform: none;
    opacity: 1;
  }
  7% {
    transform: skew(-0.5deg, -0.9deg);
    opacity: 0.85;
  }
  10% {
    transform: none;
    opacity: 1;
  }
  27% {
    transform: none;
    opacity: 1;
  }
  30% {
    transform: skew(1.8deg, -0.1deg);
    opacity: 0.85;
  }
  35% {
    transform: none;
    opacity: 1;
  }
  52% {
    transform: none;
    opacity: 1;
  }
  55% {
    transform: skew(-1deg, 0.2deg);
    opacity: 0.85;
  }
  50% {
    transform: none;
    opacity: 1;
  }
  72% {
    transform: none;
    opacity: 1;
  }
  75% {
    transform: skew(0.8deg, 2deg);
    opacity: 0.85;
  }
  80% {
    transform: none;
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 1;
  }
	}
`
const glitch2 = keyframes`
  0% {
    transform: none;
    opacity: 0.25;
  }
  7% {
    transform: translate(-2px, -3px);
    opacity: 0.5;
  }
  10% {
    transform: none;
    opacity: 0.25;
  }
  27% {
    transform: none;
    opacity: 0.25;
  }
  30% {
    transform: translate(-5px, -2px);
    opacity: 0.5;
  }
  35% {
    transform: none;
    opacity: 0.25;
  }
  52% {
    transform: none;
    opacity: 0.25;
  }
  55% {
    transform: translate(-5px, -1px);
    opacity: 0.5;
  }
  50% {
    transform: none;
    opacity: 0.25;
  }
  72% {
    transform: none;
    opacity: 0.25;
  }
  75% {
    transform: translate(-2px, -6px);
    opacity: 0.5;
  }
  80% {
    transform: none;
    opacity: 0.25;
  }
  100% {
    transform: none;
    opacity: 0.25;
  }
	}
`

const AnimatedBox = animated(Box)
const AnimatedFlex = animated(Flex)

const HeroSection = ({ handleClick }) => {
  const context = useThemeUI()
  const { theme } = context
  const headingFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 200,
  })
  const subtitleFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 350,
  })
  const buttonFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 650,
  })

  return (
    <Fragment>
      <Box
        sx={{
          variant: 'layout.sectionContainer',
          bg: 'inherit',
          width: '100vw',
          position: 'relative',
          ml: '-50vw',
          left: '50%',
          overflow: 'hidden',
        }}
      >
        <Wave wave="hero" transformFrom="top" landingHeroActivate={true} />

        <Container>
          <Flex sx={{ variant: 'layout.sectionHeroContainerDefault' }}>
            <AnimatedBox
              style={headingFade}
              sx={{
                variant: 'layout.headingContainer',
              }}
            >
              <Styled.h1 sx={{ variant: 'text.heading', zIndex: '90' }}>Web Product Design & Development</Styled.h1>
            </AnimatedBox>
            <AnimatedBox
              style={subtitleFade}
              sx={{
                variant: 'layout.subHeadingContainer',
              }}
            >
              <Styled.h4 sx={{ variant: 'text.subheading' }}>
                We help progressive organisations create{' '}
                <span
                  sx={{
                    color: theme.colors.heading,
                    position: 'relative',
                    display: 'inline-block',
                    animation: `${glitch1} 10s linear infinite`,
                    '::before': {
                      content: '"interactive"',
                      position: 'absolute',
                      top: '0%',
                      color: theme.colors.glitch,
                      width: '100%',
                      textShadow: 'none',
                      animation: `${glitch2} 10s linear infinite`,
                    },
                  }}
                >
                  interactive
                </span>{' '}
                product-driven
              </Styled.h4>
            </AnimatedBox>

            <Flex
              sx={{
                justifyContent: ['space-between', 'center', 'center', 'center'],
                height: 'auto',
              }}
              my={4}
            >
              <ProductTypes />
            </Flex>

            <AnimatedFlex style={buttonFade} sx={{ variant: 'layout.buttonContainerDefault' }}>
              <Box sx={{ m: 3 }}>
                <ButtonAnimated margin="0" variant="primary" scaleActive="1.1" label="Our services" onClick={() => handleClick(true)} actionType="scroll" />
              </Box>
              <Box sx={{ m: 3 }}>
                <ButtonAnimated margin="0" variant="outline" scaleActive="1.1" label="Our work" linkTo="/work/" />
              </Box>
            </AnimatedFlex>
          </Flex>
        </Container>
      </Box>
    </Fragment>
  )
}

export default HeroSection
