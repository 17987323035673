/** @jsx jsx */
import { jsx, Box, Styled, Flex } from 'theme-ui'
import { Fragment } from 'react'
import { animated, useSpring } from 'react-spring'
import { ButtonAnimated } from './animation'
import { useToggle } from '../hooks'
import { Waypoint } from 'react-waypoint'
import Container from './container'
import Wave from './wave'

const AnimatedFlex = animated(Flex)
const AnimatedBox = animated(Box)

const LandingWeb3Section = () => {
  const { isToggled: active, toggle: setActive } = useToggle(false)

  const headingFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 100 : 0,
  })
  const subtitleFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 350 : 0,
  })
  const buttonFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 650,
  })

  return (
    <Fragment>
      <Box
        sx={{
          variant: 'layout.sectionContainer',
          bg: 'inherit',
          width: '100vw',
          position: 'relative',
          ml: '-50vw',
          left: '50%',
          overflow: 'hidden',
        }}
      >
        {/* <Wave wave="tech" transformFrom="bottom" landingTechActivate={active} /> */}
        <Container>
          <Waypoint
            bottomOffset="25%"
            onEnter={() => {
              if (!active) setActive(true)
            }}
          />
          <Flex sx={{ variant: 'layout.sectionHeroContainerDefault' }}>
            <AnimatedBox
              style={headingFade}
              sx={{
                variant: 'layout.headingContainer',
              }}
            >
              <Styled.h1 sx={{ variant: 'text.heading' }}>Web3 & Blockchain</Styled.h1>
            </AnimatedBox>
            <AnimatedBox
              style={subtitleFade}
              sx={{
                variant: 'layout.subHeadingContainer',
              }}
            >
              <Styled.h4 sx={{ variant: 'text.subheading' }}>We help individuals and businesses navigate the Web3 ecosystem and integrate blockchain technology.</Styled.h4>
            </AnimatedBox>
            <AnimatedFlex style={buttonFade} sx={{ variant: 'layout.buttonContainerDefault' }}>
              <Box sx={{ m: 3 }}>
                <ButtonAnimated margin="0" variant="primary" scaleActive="1.1" label="Learn more" linkTo="/web3/" />
              </Box>
            </AnimatedFlex>
          </Flex>
        </Container>
      </Box>
    </Fragment>
  )
}

export default LandingWeb3Section
