/** @jsx jsx */
import { jsx, Flex, Styled } from 'theme-ui'
import { animated } from 'react-spring'

const AnimatedFlex = animated(Flex)

export default [
  ({ style }) => (
    <AnimatedFlex style={{ ...style }}>
      <Styled.h5 sx={{ px: '2' }}>Web3 dApps (decentralised apps)</Styled.h5>
    </AnimatedFlex>
  ),
  ({ style }) => (
    <AnimatedFlex style={{ ...style }}>
      <Styled.h5 sx={{ px: '2' }}>Web Apps</Styled.h5>
    </AnimatedFlex>
  ),
  ({ style }) => (
    <AnimatedFlex style={{ ...style }}>
      <Styled.h5 sx={{ px: '2' }}>Marketing and Portfolio Websites</Styled.h5>
    </AnimatedFlex>
  ),
  ({ style }) => (
    <AnimatedFlex style={{ ...style }}>
      <Styled.h5 sx={{ px: '2' }}>E-Commerce Web Apps</Styled.h5>
    </AnimatedFlex>
  ),

  // ({ style }) => (
  //   <AnimatedFlex style={{ ...style }}>
  //     <Styled.h5 sx={{ px: '2' }}>Web3 Apps</Styled.h5>
  //   </AnimatedFlex>
  // ),
  // ({ style }) => (
  //   <AnimatedFlex style={{ ...style }}>
  //     <Styled.h5 sx={{ px: '2' }}>Progressive Web Apps</Styled.h5>
  //   </AnimatedFlex>
  // ),
  // ({ style }) => (
  //   <AnimatedFlex style={{ ...style }}>
  //     <Styled.h5 sx={{ px: '2' }}>Internal Documentation Sites</Styled.h5>
  //   </AnimatedFlex>
  // ),
  // ({ style }) => (
  //   <AnimatedFlex style={{ ...style }}>
  //     <Styled.h5 sx={{ px: '2' }}>Design Systems</Styled.h5>
  //   </AnimatedFlex>
  // ),
]
