/** @jsx jsx */
import { jsx, Box, Styled, Flex } from 'theme-ui'
import { Fragment } from 'react'
import { useToggle } from '../hooks'
import { animated, useSpring } from 'react-spring'
import Container from '../components/container'
import ServiceTypes from './serviceTypes'
import { Waypoint } from 'react-waypoint'
import Wave from './wave'

const AnimatedBox = animated(Box)
const AnimatedFlex = animated(Flex)

const ServicesSection = ({ refProp }) => {
  const { isToggled: active, toggle: setActive } = useToggle(false)

  const headingFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 100 : 0,
  })
  const subtitleFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 350 : 0,
  })
  const buttonFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 550 : 0,
  })

  return (
    <Fragment>
      <Box
        sx={{
          variant: 'layout.sectionContainer',
          bg: 'inherit',
          width: '100vw',
          position: 'relative',
          ml: '-50vw',
          left: '50%',
          overflow: 'hidden',
        }}
      >
        <Wave wave="services" transformFrom="top" landingServicesActivate={true} />
        <Container>
          <Waypoint
            bottomOffset="25%"
            onEnter={() => {
              if (!active) setActive(true)
            }}
          />
          <Flex ref={refProp} sx={{ variant: 'layout.sectionHeroContainerDefault' }}>
            <AnimatedBox
              style={headingFade}
              sx={{
                variant: 'layout.headingContainer',
              }}
            >
              <Styled.h1 sx={{ variant: 'text.heading' }}>Services</Styled.h1>
            </AnimatedBox>
            <AnimatedBox
              style={subtitleFade}
              sx={{
                variant: 'layout.subHeadingContainer',
              }}
            >
              <Styled.h4 sx={{ variant: 'text.subheading' }}>Designing, building and rolling out fast, exclusive, interactive product-driven websites and apps that work the way you need them to.</Styled.h4>
            </AnimatedBox>
            <AnimatedFlex
              style={buttonFade}
              sx={{
                my: [0, 2, 4],
                px: [1, 5, 6],
                textAlign: 'center',
                flexDirection: 'column',
              }}
            >
              <ServiceTypes />
            </AnimatedFlex>
          </Flex>
        </Container>
      </Box>
    </Fragment>
  )
}

export default ServicesSection
