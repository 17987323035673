/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { Fragment } from 'react'
import { animated, useSpring } from 'react-spring'
import { useToggle } from '../hooks'
import Img from 'gatsby-image'
import NextjsLogoLight from '../assets/nextjsLight'

const AnimatedBox = animated(Box)

export const TechLogoAnimationA = ({ image, imageName }) => {
  const { isToggled: float, toggle: setFloat } = useToggle(false)

  const animationStyle = useSpring({
    from: { transform: 'translate(0px, 0px)' },
    to: { transform: `translate(0px, 20px)` },
    config: { duration: 7500 },
    reverse: float,
    onRest: () => setFloat(),
    reset: float,
  })

  return (
    <Fragment>
      <AnimatedBox style={animationStyle}>
        <Img
          fluid={image}
          title={imageName}
          alt={imageName}
          style={{
            maxHeight: '100%',
          }}
          imgStyle={{
            objectFit: 'contain',
            padding: '0.5rem',
          }}
        />
      </AnimatedBox>
    </Fragment>
  )
}

export const TechLogoAnimationB = ({ image, imageName }) => {
  const { isToggled: float, toggle: setFloat } = useToggle(false)

  const animationStyle = useSpring({
    from: { transform: 'translate(0px, 20px)' },
    to: { transform: `translate(0px, 0px)` },
    config: { duration: 7500 },
    reverse: float,
    onRest: () => setFloat(),
    reset: float,
  })

  return (
    <Fragment>
      <AnimatedBox style={animationStyle}>
        <Img
          fluid={image}
          title={imageName}
          alt={imageName}
          style={{
            maxHeight: '100%',
          }}
          imgStyle={{
            objectFit: 'contain',
            padding: '0.5rem',
          }}
        />
      </AnimatedBox>
    </Fragment>
  )
}

export const TechLogoAnimationC = () => {
  const { isToggled: float, toggle: setFloat } = useToggle(false)

  const animationStyle = useSpring({
    from: { transform: 'translate(0px, 0px)' },
    to: { transform: `translate(0px, 20px)` },
    config: { duration: 7500 },
    reverse: float,
    onRest: () => setFloat(),
    reset: float,
  })

  return (
    <Fragment>
      <AnimatedBox style={animationStyle} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'inherit', contain: 'content' }}>
        <NextjsLogoLight width="100%" height="80%" />
      </AnimatedBox>
    </Fragment>
  )
}
