/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import Layout from './layout'
import { useLocation } from '@reach/router'
import { useState, useEffect, useRef } from 'react'
import smoothscroll from 'smoothscroll-polyfill'
import SEO from './SEO/seo'
import HeroSection from './heroSection'
import LandingWeb3Section from './landingWeb3Section'
import ServicesSection from './servicesSection'
import TechnologySection from './technologySection'
import WorkSection from './workSection'
import ContactSection from './contactSection'

const LandingPage = ({ data }) => {
  const { pathname } = useLocation()
  const featuredProjects = data.allProjectsJson.nodes

  const [scroll, setScroll] = useState(false)

  const myRef = useRef(null)

  const shoudlScroll = (props) => {
    smoothscroll.polyfill()
    setScroll(props)
  }

  useEffect(() => {
    if (scroll) {
      myRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    return function cleanup() {
      setScroll(false)
    }
  }, [scroll])

  return (
    <Layout location={pathname}>
      <SEO title="Gramercy Studios | Web Development & Product Design" page="landing" />
      <Flex
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <HeroSection handleClick={shoudlScroll} />
        <LandingWeb3Section />
        <TechnologySection data={data} />
        <ServicesSection refProp={myRef} />
        <WorkSection data={data} featuredProjects={featuredProjects} />
        <ContactSection variant="contact" heading="Have a question?" linkToFirstButton="/contact/" />
      </Flex>
    </Layout>
  )
}

export default LandingPage
