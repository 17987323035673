/** @jsx jsx */
import { jsx, Box, Grid, Styled, useColorMode, Flex } from 'theme-ui'
import { Fragment } from 'react'
import { animated, useSpring } from 'react-spring'
import { useToggle } from '../hooks'
import { Waypoint } from 'react-waypoint'
import Container from '../components/container'
import { TechLogoAnimationA, TechLogoAnimationB, TechLogoAnimationC } from './techLogoAnimation'
import Wave from './wave'

const AnimatedBox = animated(Box)
const AnimatedThemeGrid = animated(Grid)

const TechnologySection = ({ data }) => {
  const { isToggled: active, toggle: setActive } = useToggle(false)
  // const { isToggled: waveActive, toggle: setWaveActive } = useToggle(false)

  // eslint-disable-next-line
  const [colorMode, setColorMode] = useColorMode()

  const headingFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 100 : 0,
  })
  const subtitleFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 350 : 0,
  })
  const gridFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 550 : 0,
  })
  const copyFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 750 : 0,
  })

  return (
    <Fragment>
      <Box
        sx={{
          variant: 'layout.sectionContainer',
          bg: 'inherit',
          width: '100vw',
          position: 'relative',
          ml: '-50vw',
          left: '50%',
          overflow: 'hidden',
        }}
      >
        <Wave wave="tech" transformFrom="bottom" landingTechActivate={active} />
        <Container>
          <Waypoint
            bottomOffset="25%"
            onEnter={() => {
              if (!active) setActive(true)
            }}
          />
          <Flex sx={{ variant: 'layout.sectionHeroContainerDefault' }}>
            <AnimatedBox
              style={headingFade}
              sx={{
                variant: 'layout.headingContainer',
              }}
            >
              <Styled.h1 sx={{ variant: 'text.heading' }}>Give your web app the edge</Styled.h1>
            </AnimatedBox>
            <AnimatedBox
              style={subtitleFade}
              sx={{
                variant: 'layout.subHeadingContainer',
              }}
            >
              <Styled.h4 sx={{ variant: 'text.subheading' }}>We work with React, Node.js, GraphQL and the extended JavaScript ecosystem, including the React-based frameworks like Next.js to deliver dynamic, super fast web apps.</Styled.h4>
            </AnimatedBox>
            <AnimatedThemeGrid style={gridFade} gap={[4, 2, 2, 2]} columns={[`300px`, `200px 200px 200px`, `260px 260px 260px`]} variant="primary">
              <TechLogoAnimationA imageName={data.reactLogoImage.childImageSharp.fluid.originalName} image={data.reactLogoImage.childImageSharp.fluid} />
              <TechLogoAnimationB imageName={data.graphqlLogoImage.childImageSharp.fluid.originalName} image={data.graphqlLogoImage.childImageSharp.fluid} />
              <TechLogoAnimationA imageName={data.nodeLogoImage.childImageSharp.fluid.originalName} image={data.nodeLogoImage.childImageSharp.fluid} />
            </AnimatedThemeGrid>
            <AnimatedThemeGrid style={gridFade} gap={[4, 2, 2, 2]} columns={[`300px`, `200px 200px`, `260px 260px`]} variant="secondary">
              {colorMode === 'dark' ? <TechLogoAnimationC /> : <TechLogoAnimationB imageName={data.nextLogoImage.childImageSharp.fluid.originalName} image={data.nextLogoImage.childImageSharp.fluid} />}
              <TechLogoAnimationA imageName={data.gatsbyLogoImage.childImageSharp.fluid.originalName} image={data.gatsbyLogoImage.childImageSharp.fluid} />
            </AnimatedThemeGrid>
            <AnimatedBox
              style={copyFade}
              sx={{
                variant: 'layout.subHeadingContainer',
              }}
            >
              <Styled.h5 sx={{ variant: 'text.subheading' }}>Not only will your software be cutting edge, it’ll be rock solid for you as you grow.</Styled.h5>
            </AnimatedBox>
          </Flex>
        </Container>
      </Box>
    </Fragment>
  )
}

export default TechnologySection
