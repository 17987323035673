// Gradients taken from: https://webgradients.com/
export default [
  // {
  //   name: 'Application Architecture',
  //   type: 'Services',
  //   css: `linear-gradient(135deg, rgba(81, 94, 114, 1) 100%, rgba(113, 129, 156, 0.65) 100%)`,
  //   // css: 'linear-gradient(135deg, rgba(190,75,82,0.75) 40%, rgba(217,231,108,1) 100%)',
  //   // height: 400,
  // },
  {
    name: 'Interface & Interaction Design',
    type: 'Services',
    css: `linear-gradient(135deg, rgba(81, 94, 114, 1) 100%, rgba(113, 129, 156, 0.65) 100%)`,
    // css: 'linear-gradient(135deg, rgba(2,190,214,0.75) 50%, rgba(217,231,108,1) 100%)',
  },
  {
    name: 'User Experience Design',
    type: 'Services',
    css: `linear-gradient(135deg, rgba(81, 94, 114, 1) 100%, rgba(113, 129, 156, 0.65) 100%)`,
    // css: 'linear-gradient(135deg, rgba(190,75,82,0.75) 50%, rgba(217,231,108,1) 100%)',
  },
  {
    name: 'Front and Back-End Development',
    type: 'Services',
    css: `linear-gradient(135deg, rgba(81, 94, 114, 1) 100%, rgba(113, 129, 156, 0.65) 100%)`,
    // css: 'linear-gradient(135deg, rgba(2,190,214,0.75) 50%, rgba(217,231,108,1) 100%)',
  },
  {
    name: 'Content Management Systems (CMS)',
    type: 'Services',
    css: `linear-gradient(135deg, rgba(81, 94, 114, 1) 100%, rgba(113, 129, 156, 0.65) 100%)`,
    // css: 'linear-gradient(135deg, rgba(190,75,82,0.75) 50%, rgba(217,231,108,1) 100%)',
  },
  {
    name: 'Blockchain Smart Contracts',
    type: 'Services',
    css: `linear-gradient(135deg, rgba(81, 94, 114, 1) 100%, rgba(113, 129, 156, 0.65) 100%)`,
    // css: 'linear-gradient(135deg, rgba(2,190,214,0.75) 50%, rgba(217,231,108,1) 100%)',
  },
  {
    name: 'Web3 Education & Consulting',
    type: 'Services',
    css: `linear-gradient(135deg, rgba(81, 94, 114, 1) 100%, rgba(113, 129, 156, 0.65) 100%)`,
    // css: 'linear-gradient(135deg, rgba(190,75,82,0.75) 50%, rgba(217,231,108,1) 100%)',
  },
  // {
  //   name: 'E-Commerce Development',
  //   type: 'Services',
  //   css: `linear-gradient(135deg, rgba(81, 94, 114, 1) 100%, rgba(113, 129, 156, 0.65) 100%)`,
  //   // css: 'linear-gradient(135deg, rgba(2,190,214,0.75) 50%, rgba(217,231,108,1) 100%)',
  // },
]
