/** @jsx jsx */
import { jsx, Flex, useThemeUI } from 'theme-ui'
import { useState } from 'react'
import { useTransition } from 'react-spring'
import { Fragment } from 'react'
import ProductData from '../data/landing/products'
import { ButtonAnimatedIcon } from './animation'
import Icon from '../../utils/icon'

export const ProductTypes = () => {
  const [activeProduct, setProduct] = useState(0)
  const [activeButton, setButton] = useState('next')

  const context = useThemeUI()
  const { theme } = context

  const nextProduct = () => {
    if (activeProduct === ProductData.length - 1) {
      return setProduct(activeProduct)
    }
    setProduct(activeProduct + 1)
    setButton('next')
  }

  const prevProduct = () => {
    if (activeProduct === 0) {
      return setProduct(activeProduct)
    }
    setProduct(activeProduct - 1)
    setButton('prev')
  }

  const transitions = useTransition(activeProduct, (p) => p, {
    from: {
      opacity: 0,
      transform: activeButton === 'prev' ? 'translate3d(-300%,0,0)' : 'translate3d(300%,0,0)',
      position: 'absolute',
    },
    enter: {
      opacity: 1,
      transform: activeButton === 'prev' ? 'translate3d(0%,0,0)' : 'translate3d(0%,0,0)',
    },
    leave: {
      opacity: 0,
      transform: activeButton === 'prev' ? 'translate3d(300%,0,0)' : 'translate3d(-300%,0,0)',
    },
  })
  return (
    <Fragment>
      <Flex onClick={prevProduct}>
        <ButtonAnimatedIcon aria-label="Previous button" scaleActive={activeProduct === 0 ? '1' : '1.1'} cursor={activeProduct === 0 ? 'not-allowed' : 'pointer'}>
          <Icon name="arrowLeft" width="30" height="30" color={activeProduct === 0 ? `${theme.colors.carouselButtonDisable}` : `${theme.colors.carouselButton}`} />
        </ButtonAnimatedIcon>
      </Flex>
      <Flex
        sx={{
          flex: ['0 0 65%', '0 0 45%', '0 0 35%'],
          textAlign: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          contain: 'content',
          py: 4,
          px: 1,
        }}
      >
        {transitions.map(({ item, key, props }) => {
          const Product = ProductData[item]

          return <Product key={key} style={props} />
        })}
      </Flex>
      <Flex onClick={nextProduct}>
        <ButtonAnimatedIcon aria-label="Next button" scaleActive={activeProduct === ProductData.length - 1 ? '1' : '1.1'} cursor={activeProduct === ProductData.length - 1 ? 'not-allowed' : 'pointer'}>
          <Icon name="arrowRight" width="30" height="30" color={activeProduct === ProductData.length - 1 ? `${theme.colors.carouselButtonDisable}` : `${theme.colors.carouselButton}`} />
        </ButtonAnimatedIcon>
      </Flex>
    </Fragment>
  )
}
