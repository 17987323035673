import React from 'react'
import { graphql } from 'gatsby'
import LandingPage from '../components/landingPage'

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
        originalName
      }
    }
  }
`

export const fixedImage = graphql`
  fragment fixedImage on File {
    childImageSharp {
      fixed {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

export const ALL_DATA_QUERY = graphql`
  query ALL_DATA_QUERY {
    graphqlLogoImage: file(relativePath: { regex: "/GraphQL/" }) {
      ...fluidImage
    }
    gatsbyLogoImage: file(relativePath: { regex: "/Gatsby/" }) {
      ...fluidImage
    }
    reactLogoImage: file(relativePath: { regex: "/React/" }) {
      ...fluidImage
    }
    nodeLogoImage: file(relativePath: { regex: "/Node/" }) {
      ...fluidImage
    }
    nextLogoImage: file(relativePath: { regex: "/Next/" }) {
      ...fluidImage
    }
    reactLogoImage: file(relativePath: { regex: "/React/" }) {
      ...fixedImage
    }
    spotexLogoImage: file(relativePath: { regex: "/Spotex/" }) {
      ...fluidImage
    }
    riskwestLogoImage: file(relativePath: { regex: "/Riskwest/" }) {
      ...fluidImage
    }
    allProjectsJson(filter: { featured: { eq: true } }) {
      nodes {
        slug
        featured
        icon {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        iconName
        name
        clientType
        internetStage
        productType
        description
        stack
      }
    }
  }
`

const LandingPageTemplate = ({ data }) => {
  // const featuredProjects = data.allProjectsJson.nodes

  return <LandingPage data={data} />
}

export default LandingPageTemplate
